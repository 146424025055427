(function () {
        'use strict';
        // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
        angular
            .module('garanteasyApp')
            		.constant('VERSION', "3.3.5")
		.constant('DEBUG_INFO_ENABLED', false)
		.constant('DEFAULT_BARCODE_ENCODING', "EAN13")
		.constant('ROLE_USER', "ROLE_USER")
		.constant('API_URI', "https://api.garanteasy.com/")
		.constant('CMS_URI', "https://cms.garanteasy.com/")

            .config(['$sceDelegateProvider', function ($sceDelegateProvider) {
                $sceDelegateProvider.resourceUrlWhitelist([
                // Allow same origin resource loads.
                'self',
                // Allow loading from our assets domain. **.
                "https://api.garanteasy.com/" + '**'
            ])
          }])
    ;})();